<template>
    <div class="page-table tabStyle mainDiv mb-30" id="affix-container">
        <div class="card-base baslikBg animated fadeInUp">
            {{$t("src.views.apps.urun.update.title")}}

            <div class="tanimlamaButon">
                <el-tooltip :content='$t("src.views.apps.genel.refresh")' :open-delay="500" placement="top">
                    <el-button v-on:click="refreshPage()" style="margin-left: 15px !important" type="text" icon="el-icon-refresh" class="routeButton"></el-button>
                </el-tooltip>
            </div>
        </div>

        <el-row :gutter="10" v-loading="loading || detailLoading"
            :element-loading-text='loading ? $t("src.views.apps.urun.update.updateLoading") : $t("src.views.apps.urun.update.detailLoading")'
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(255, 255, 255, 1)">
            <el-form status-icon :model="urunForm" :rules="rulesUrun" ref="urunForm" class="demo-ruleForm">
                <el-col :lg="18" :md="18" :sm="24" :xs="24">
                    <div class="card-base card-shadow--medium demo-box bg-white animated fadeInUp">
                        <el-row class="mb-15">
                            <el-col :lg="12" :md="12" :sm="12" :xs="12" class="col-p">
                                <el-form-item :label='$t("src.views.apps.urun.tanimlama.baslik")' prop="urunBaslik">:
                                    <el-input v-model="urunForm.urunBaslik" v-on:change="linkTespit" @input="linkTespit" :placeholder='$t("src.views.apps.urun.tanimlama.baslikPlace")'></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="12" :md="12" :sm="12" :xs="12" class="col-p">
                                <el-form-item :label='$t("src.views.apps.urun.tanimlama.altBaslik")' prop="altBaslik">:
                                    <el-input v-model="urunForm.altBaslik" v-on:change="linkTespit" @input="linkTespit" :placeholder='$t("src.views.apps.urun.tanimlama.altBaslikPlace")'></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>                   

                        <el-row>
                            <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                <el-form-item :label='$t("src.views.apps.urun.update.explanation")' prop="icerik">:
                                    <ckeditor :editor="editor" :config="editorConfig" v-model="urunForm.icerik" @input="seoIcerikTespit" @blur="seoIcerikTespit"></ckeditor>
                                </el-form-item>
                            </el-col>
                        </el-row>  
                    </div>

                    <el-collapse style="margin-top: 10px" v-model="activeCollapse">

                        <el-collapse-item :title='$t("src.views.apps.urun.update.imageSetting")' name="2">
                             <Resim functionName="urunGuncelleImage" :imageList="imageList" :link="urunForm.link" :selectedsFromServer="selectedImagesFromServer"></Resim>
                        </el-collapse-item>

                        <el-collapse-item :title='$t("src.views.apps.urun.seoSetting")' name="3">
                            <el-row>
                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item :label='$t("src.views.apps.sayfa.tanimlama.seoBaslik")' prop="seoBaslik">:
                                        <el-input v-model="urunForm.seoBaslik" maxlength="60" show-word-limit></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item
                                        :label='$t("src.views.apps.sayfa.tanimlama.seoIcerik")' prop="seoIcerik">:
                                        <el-input type="textarea" maxlength="140" v-on:focus="seoIcerikChange = false" show-word-limit v-model="urunForm.seoIcerik"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-collapse-item>


                    </el-collapse>


                </el-col>


                <el-col :lg="6" :md="6" :sm="24" :xs="24">
                    <el-collapse class="yanForm animated fadeInUp delay-1s" v-model="activeCollapse">
                        <el-collapse-item class="card-shadow--medium" name="1" title="Durum">
                            <el-row>
                               
                                <el-col :lg="24" :md="24" :sm="24" :xs="24" >
                                    <div class="display-end">
                                        <el-popover placement="bottom" width="310" v-model="visibleSil">
                                            <p>{{$t('src.views.apps.urun.kategori.tanimlama.formAlertP')}}</p>
                                            <div style="text-align: right; margin: 0">
                                                <el-button size="mini" type="primary" @click="resetForm('urunForm')">
                                                    {{$t('src.views.apps.urun.kategori.tanimlama.evet')}}
                                                </el-button>
                                                <el-button size="mini" @click="visibleSil = false;">
                                                    {{$t('src.views.apps.urun.kategori.tanimlama.hayir')}}
                                                </el-button>
                                            </div>
                                            <el-button size="mini" slot="reference">
                                                {{$t('src.views.apps.urun.kategori.tanimlama.temizle')}}
                                            </el-button>
                                        </el-popover>

                                        &nbsp;

                                        <el-button  size="mini" v-on:click="urunGuncelleme('urunForm')" class="onayBtn" type="primary">
                                            <label v-if="urunForm.durum === '1'">{{$t('src.views.apps.urun.update.guncelle')}}</label>
                                            <label v-else>{{$t("src.views.apps.urun.update.guncelle")}}</label>
                                        </el-button>
                                        
                                    </div>
                                </el-col>
                            </el-row>
                        </el-collapse-item>

                        <!--
                        <el-collapse-item class="card-shadow--medium"
                                          :title='$t("src.views.apps.sayfa.tanimlama.resimKonum")' name="4">
                            <el-row>
                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item prop="resimKonum">
                                        <el-button type="primary" size="mini" @click="drawerResimKonum = true">
                                            {{this.btnResim}}
                                        </el-button>
                                        &nbsp;
                                        <img v-if="urunForm.resimKonum === '0'" style="float: right"
                                            src="../../../assets/images/1.png" width="60%" height="60%">
                                        <img v-if="urunForm.resimKonum === '1'" style="float: right"
                                            src="../../../assets/images/2.png" width="60%" height="60%">
                                        <img v-if="urunForm.resimKonum === '2'" style="float: right"
                                            src="../../../assets/images/5.png" width="60%" height="60%">
                                        <img v-if="urunForm.resimKonum === '3'" style="float: right"
                                            src="../../../assets/images/4.png" width="60%" height="60%">
                                        <img v-if="urunForm.resimKonum === '4'" style="float: right"
                                            src="../../../assets/images/3.png" width="60%" height="60%">
                                        </el-form-item>
                                        <div style="font-style: italic">
                                            {{$t("src.views.apps.sayfa.tanimlama.resimKonumAciklama")}}
                                        </div>
                                    </el-col>
                                </el-row>                                           
                        </el-collapse-item>

                        <el-collapse-item class="card-shadow--medium"
                                              :title='$t("src.views.apps.sayfa.tanimlama.menuKonum")' name="5">
                                <el-row>
                                    <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                        <el-form-item prop="menuKonum">
                                            <el-button type="primary" size="mini" @click="drawerMenuKonum = true">
                                                {{this.btnMenu}}
                                            </el-button>
                                            &nbsp;
                                            <img v-if="urunForm.menuKonum === '0'" style="float: right"
                                                 src="../../../assets/images/1.png" width="60%" height="60%">
                                            <img v-if="urunForm.menuKonum === '1'" style="float: right"
                                                 src="../../../assets/images/6.png" width="60%" height="60%">
                                            <img v-if="urunForm.menuKonum === '2'" style="float: right"
                                                 src="../../../assets/images/9.png" width="60%" height="60%">
                                        </el-form-item>
                                        <div style="font-style: italic">
                                            {{$t("src.views.apps.sayfa.tanimlama.menuKonumAciklama")}}
                                        </div>
                                    </el-col>
                                </el-row>
                        </el-collapse-item>

                        <el-collapse-item class="card-shadow--medium"
                                              :title='$t("src.views.apps.sayfa.tanimlama.galeriKonum")' name="6">
                                <el-row>
                                    <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                        <el-form-item prop="galeriKonum">
                                            <el-button type="primary" size="mini" @click="drawerGaleriKonum = true">
                                                {{this.btnGaleri}}
                                            </el-button>
                                            &nbsp;
                                            <img v-if="urunForm.galeriKonum === '0'" style="float: right"
                                                 src="../../../assets/images/1.png" width="60%" height="60%">
                                            <img v-if="urunForm.galeriKonum === '1'" style="float: right"
                                                 src="../../../assets/images/7.png" width="60%" height="60%">
                                            <img v-if="urunForm.galeriKonum === '2'" style="float: right"
                                                 src="../../../assets/images/8.png" width="60%" height="60%">
                                        </el-form-item>
                                        <div style="font-style: italic">
                                            {{$t("src.views.apps.sayfa.tanimlama.galeriKonumAciklama")}}
                                        </div>
                                    </el-col>
                                </el-row>
                        </el-collapse-item>-->

                    </el-collapse>
                </el-col>
            </el-form>
            <!--
            <el-dialog
                    title="Resim Konumu"
                    :visible.sync="drawerResimKonum"
                    width="60%">
                <el-row>
                    <el-col :span="8">
                        <el-radio v-model="urunForm.resimKonum" label="0" @change="changeKonum('resim','0')">
                            <img src="../../../assets/images/1.png" alt="Yok" width="90%" height="90%">
                        </el-radio>
                    </el-col>
                    <el-col :span="8">
                        <el-radio v-model="urunForm.resimKonum" label="1" @change="changeKonum('resim','1')">
                            <img src="../../../assets/images/2.png" alt="Sağ" width="90%" height="90%">
                        </el-radio>
                    </el-col>
                    <el-col :span="8">
                        <el-radio v-model="urunForm.resimKonum" label="2" @change="changeKonum('resim','2')">
                            <img src="../../../assets/images/5.png" alt="Alt" width="90%" height="90%">
                        </el-radio>
                    </el-col>
                    <el-col :span="8">
                        <el-radio v-model="urunForm.resimKonum" label="3" @change="changeKonum('resim','3')">
                            <img src="../../../assets/images/4.png" alt="Sol" width="90%" height="90%">
                        </el-radio>
                    </el-col>
                    <el-col :span="8">
                        <el-radio v-model="urunForm.resimKonum" label="4" @change="changeKonum('resim','4')">
                            <img src="../../../assets/images/3.png" alt="Üst" width="90%" height="90%">
                        </el-radio>
                    </el-col>
                </el-row>
            </el-dialog>
            <el-dialog
                    title="Menü Konumu"
                    :visible.sync="drawerMenuKonum"
                    width="50%">
                <el-row>
                    <el-col :span="8">
                        <el-radio v-model="urunForm.menuKonum" label="0" @change="changeKonum('menu','0')">
                            <img src="../../../assets/images/1.png" alt="Yok" width="80%" height="80%">
                        </el-radio>
                    </el-col>
                    <el-col :span="8">
                        <el-radio v-model="urunForm.menuKonum" label="1" @change="changeKonum('menu','1')">
                            <img src="../../../assets/images/6.png" alt="Sağ" width="80%" height="80%">
                        </el-radio>
                    </el-col>
                    <el-col :span="8">
                        <el-radio v-model="urunForm.menuKonum" label="2" @change="changeKonum('menu','2')">
                            <img src="../../../assets/images/9.png" alt="Alt" width="80%" height="80%">
                        </el-radio>
                    </el-col>
                </el-row>
            </el-dialog>
            <el-dialog
                    title="Galeri Konumu"
                    :visible.sync="drawerGaleriKonum"
                    width="50%">
                <el-row>
                    <el-col :span="8">
                        <el-radio v-model="urunForm.galeriKonum" label="0" @change="changeKonum('galeri','0')">
                            <img src="../../../assets/images/1.png" alt="Yok" width="80%" height="80%">
                        </el-radio>
                    </el-col>
                    <el-col :span="8">
                        <el-radio v-model="urunForm.galeriKonum" label="1" @change="changeKonum('galeri','1')">
                            <img src="../../../assets/images/7.png" alt="Dikey,Büyük" width="80%" height="80%">
                        </el-radio>
                    </el-col>
                    <el-col :span="8">
                        <el-radio v-model="urunForm.galeriKonum" label="2" @change="changeKonum('galeri','2')">
                            <img src="../../../assets/images/8.png" alt="Yatay,Küçük" width="80%">
                        </el-radio>
                    </el-col>
                </el-row>
            </el-dialog>
            -->
        </el-row>
    </div>
</template>

<script>
    import VueToggles from "vue-toggles"

    import ClassicEditor from '../../../../node_modules/ckeditor5-build-classic';
    import EventBus from '@/components/event-bus'
    import urunService from '../../../WSProvider/UrunService'    
    import notification from '../../../notification'
    import functions from '../../../functions'
    import Resim from "../../../components/Resim.vue";
    import ImageCrop from "../../../components/ImageCrop.vue"
    import JQuery from 'jquery';


    let $ = JQuery;

    export default {
        name: "UrunDuzenle",
        components: {Resim, ImageCrop, VueToggles},

        mounted() {
            const self = this;
            this.urunForm.urunID = this.$store.getters.getUrunUpdateData;
            this.getUrunDetay();

            EventBus.$on("urunGuncelleImage", function(bool, imageArray) {
                if (bool) {
                    self.urunForm.resim = imageArray;
                }
            });           
        },

        computed: {
            getUrunUpdateData(){
                return this.$store.getters.getUrunUpdateData
            },
        },

        watch: {
            getUrunUpdateData(val){
                this.urunForm.urunID = val;
                this.getUrunDetay();
            }
        },

        data() {
            return {
                btnResim: "Seç",
                btnMenu: "Seç",
                btnGaleri: "Seç",

                ozellikler: [],
                detailLoading: false,
                showContent: false,
                imageCropDialog: false,
                drawerResimKonum: false,
                drawerMenuKonum: false,
                drawerGaleriKonum: false,

                secili: false,
                
                loading: false,
                imagePath: urunService.imagePath,
                imageList: [],
                seoIcerikChange: true,
                selectedImagesFromServer: [],
                
                editor: ClassicEditor,
                editorConfig: {
                    language: 'tr'
                },
                activeCollapse: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15'],
                visibleSil: false,
                checked: true,

                urunForm: {
                    urunID: "",
                    urunBaslik: "",
                    altBaslik: "",
                    baslik:"",
                    icerik: "",                    
                    link: '',
                    seoBaslik: '',
                    seoIcerik: '',
                    resim: [],
                    durum: "1",
                    resimKonum: "0",
                    menuKonum: "0",
                    galeriKonum: "0"
                },

                rulesUrun: {
                    urunBaslik: [{required: true, message: this.$t("src.views.apps.urun.messages.productNameRequire"), trigger: 'change'}],
                    //resim: [{required: true, message: this.$t("src.views.apps.messages.selectImage"), trigger: 'change'}],               
                },
            }
        },

        methods: {

            refreshPage(){
                this.loading = false; 
                this.getUrunDetay(this.urunForm.urunID);
            },            
            
            getUrunDetay() {
                try {
                    this.detailLoading = true;
                    this.urunForm.resim = [];
                    this.imageList = [];

                    urunService.urunDetay(this.urunForm.urunID).then((response) => {
                        if (response.status === 200) {                          

                            if (response.resimler && response.resimler.length > 0) {  
                                response.resimler.forEach(element => { 
                                    if (element.resim !== "" && element.resim) {
                                        var item = {
                                            tmp_name: element.resim,
                                            name: element.resim,
                                            url: this.imagePath + element.resim,
                                        };
                                        this.imageList.push(item);
                                    }
                                });
                            }

                            else if (response.data.resim && response.data.resim !== "") {
                                var item = {
                                    tmp_name: response.data.resim,
                                    name: response.data.resim,
                                    url: this.imagePath + response.data.resim,
                                };
                                this.imageList.push(item);
                            }                            

                            this.urunForm.link = response.data.link;
                            this.urunForm.urunBaslik = response.data.baslik;
                            this.urunForm.altBaslik = response.data.altBaslik;
                            this.urunForm.icerik = response.data.icerik;
                            this.urunForm.durum = response.data.durum;

                            this.linkTespit();
                            this.seoIcerikTespit();

                        }

                        this.urunForm = functions.removeSlashesAndDecodeStrings(this.urunForm);
                        this.detailLoading = false;
                    }).catch(err => {
                        if (err.responseJSON) {
                            let error = err.responseJSON;
                            if (error.status == 401) {
                                notification.Status(150, this);
                            } else {
                                notification.Status("errorMsg", this, error.errMsg);
                            }
                        } else {
                            //notification.Status(503, this)
                            console.log(err);
                        }
                        this.detailLoading = false;
                    });
                } catch (e) {
                    this.detailLoading = false;
                }
            },

            urunGuncelleme(formName) {
                if (this.validateForm(formName)) {
                    try {
                        this.loading = true;


                        urunService.urunGuncelle(
                            this.urunForm.urunID,
                            this.urunForm.urunBaslik,
                            this.urunForm.altBaslik,
                            this.urunForm.resim.length > 0 ? this.urunForm.resim : '',
                            this.urunForm.icerik,
                            this.urunForm.resimKonum,
                            this.urunForm.menuKonum,
                            this.urunForm.galeriKonum,
                            this.urunForm.durum,
                            this.urunForm.seoBaslik,
                            this.urunForm.seoIcerik,
                            this.urunForm.link
                        )
                        .then((response) => {
                            if (response.status == 200) {
                                this.resetForm(formName);
                            }
                            EventBus.$emit("urunListeGuncelle");
                            localStorage.setItem("userDataDemirag", response.token);
                            notification.Status("success", this, response.msg);
                            this.sayfaKapat('urunguncelle');
                            this.loading = false;
                        })
                        .catch((err) => {
                            if (err.responseJSON) {
                                let error = err.responseJSON;
                                if (error.status == 401) {
                                    notification.Status(150, this);
                                } else {
                                    notification.Status("errorMsg", this, error.errMsg);
                                }
                            } else {
                                //notification.Status(503, this)
                            }
                            this.loading = false;
                        });
                    } catch (e) {
                        this.loading = false;
                    }
                }
            },

            changeKonum(item, konum) {
                if (item === 'resim') {
                    this.drawerResimKonum = false;
                    this.btnResim = "Değiştir";
                    switch (konum) {
                        case '0' :
                            notification.Status('success', this, 'Resim konumu "Yok" olarak seçildi.');
                            break;
                        case '1' :
                            notification.Status('success', this, 'Resim konumu "Sağ" olarak seçildi.');
                            break;
                        case '2' :
                            notification.Status('success', this, 'Resim konumu "Alt" olarak seçildi.');
                            break;
                        case '3' :
                            notification.Status('success', this, 'Resim konumu "Sol" olarak seçildi.');
                            break;
                        case '4' :
                            notification.Status('success', this, 'Resim konumu "Üst" olarak seçildi.');
                            break;
                    }
                } else if (item === 'menu') {
                    this.drawerMenuKonum = false;
                    this.btnMenu = "Değiştir";
                    switch (konum) {
                        case '0' :
                            notification.Status('success', this, 'Menü konumu "Yok" olarak seçildi.');
                            break;
                        case '1' :
                            notification.Status('success', this, 'Menü konumu "Sağ" olarak seçildi.');
                            break;
                        case '2' :
                            notification.Status('success', this, 'Menü konumu "Alt" olarak seçildi.');
                            break;
                    }
                } else if (item === 'galeri') {
                    this.drawerGaleriKonum = false;
                    this.btnGaleri = "Değiştir";
                    switch (konum) {
                        case '0' :
                            notification.Status('success', this, 'Galeri konumu "Yok" olarak seçildi.');
                            break;
                        case '1' :
                            notification.Status('success', this, 'Galeri konumu "Dikey, Büyük" olarak seçildi.');
                            break;
                        case '2' :
                            notification.Status('success', this, 'Galeri konumu "Yatay, Küçük" olarak seçildi.');
                            break;
                    }
                }
            },
            
              handleNodeClick(val) {
            },

            linkTespit() {
                this.urunForm.seoBaslik = this.urunForm.urunBaslik.substring(0, 60);
            },

            seoIcerikTespit() {
                if (this.seoIcerikChange) {
                    const myStr = functions.strip_html_tags(this.urunForm.icerik);
                    this.urunForm.seoIcerik = myStr.substring(0, 140);
                }
            },

            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.visibleSil = false;
                this.imageList = [];
                this.urunForm.resim = [];
                
                $('.el-upload--picture-card').show();
                this.sayfaKapat('urunguncelle')
            },
            
        }
    }


</script>


<style lang="scss" scoped>
    @import '../../../assets/scss/_variables';

    
    .el-radio{
        display: block !important;
    }

    #transfer1 {
        display: flex;
    }

    #transfer2 {
        display: flex;
        position: relative;
        left: 25px;
    }

    .aciklamaSpan {
        position: absolute;
        top: 25px;
    }

    .page-table {
        &.overflow {
            overflow: hidden;
        }

        .table-box {
            overflow: hidden;
        }
    }

    .el-form-item {
        font-weight: bold;
    }

    

</style>